import * as React from 'react';
import { HeadFC } from 'gatsby';
import Seo from '../components/seo';
import Layout from '../components/layout';
import { Section, Wrapper } from '../styles/contactenos.elements';

const DataPage = () => (
  <Layout>
    <main>
      <Section>
        <Wrapper>
          <div><p><strong>POLÍTICA DE TRATAMIENTO DE DATOS PERSONALES </strong></p>
            <p><strong>ELECTRIREDES LUSSAN SAS</strong></p>
            <p>&nbsp;</p>
            <ol>
              <li><strong> Consideraciones</strong></li>
            </ol>
            <p><strong>&nbsp;</strong></p>
            <p>Dando cumplimiento a lo dispuesto en la Ley Estatutaria 1581 de 2012 y a su Decreto Reglamentario 1377 de 2013, <strong>ELECTRIREDES LUSSAN SAS</strong><strong>,</strong> adopta la presente política para el tratamiento de datos personales, la cual será informada a todos los titulares de los datos recolectados o que en el futuro se obtengan en el ejercicio de las actividades, comerciales o laborales que desempeñe la compañía.</p>
            <p><strong>&nbsp;</strong><strong>ELECTRIREDES LUSSAN SAS</strong><strong>, </strong>como entidad responsable y/o encargada del tratamiento de datos personales, generó la siguiente política de tratamiento de datos personales de sus clientes, trabajadores y usuarios que garantiza los derechos de privacidad, intimidad, y buen nombre, en el tratamiento de los datos personales, en consecuencia, todas sus actuaciones se regirán por los principios:</p>
            <p><strong>Principio de Legalidad: </strong>el Tratamiento de datos es una actividad reglada que debe sujetarse a lo establecido en la ley y las demás disposiciones que la desarrollen.</p>
            <p><strong>Principio de Finalidad:</strong> el tratamiento debe obedecer a una finalidad legítima de acuerdo con la Constitución y la Ley, la cual debe ser informada al titular. En lo correspondiente a la recolección de datos personales, <strong>ELECTRIREDES LUSSAN SAS</strong><strong>, </strong>se limitará a aquellos datos que sean pertinentes y adecuados para la finalidad con la cual fueron recolectados o requeridos.</p>
            <p><strong>Principio de Libertad:</strong> el tratamiento solo puede ejercerse con el consentimiento previo, expreso, e informado del titular. Los datos personales no podrán ser obtenidos o divulgados sin previa autorización, o en ausencia de mandato legal o judicial que releve el consentimiento.</p>
            <p><strong>Principio de Calidad:</strong> la información sujeta a tratamiento debe ser veraz, completa, exacta, actualizada, comprobable y comprensible. Se prohíbe el tratamiento de datos parciales, incompletos, fraccionados o que induzcan a error.</p>
            <p><strong>Principio de Transparencia:</strong> en el tratamiento debe garantizarse el derecho del titular a obtener del responsable del tratamiento o del encargado del tratamiento, en cualquier momento y sin restricciones, información acerca de la existencia de datos que le conciernan.</p>
            <p><strong>Principio de Acceso y Circulación Restrictiva:</strong> el tratamiento se sujeta a los límites que se derivan de la naturaleza de los datos personales, de las disposiciones de la ley y la Constitución. En este sentido, el tratamiento sólo podrá hacerse por personas autorizadas por el titular y/o por las personas previstas en la ley. Los datos personales, salvo la información pública, no podrán estar disponibles en internet u otros medios de divulgación o comunicación masiva, salvo que el acceso sea técnicamente controlable para brindar un conocimiento restringido sólo a los titulares o terceros autorizados conforme a la ley.</p>
            <p><strong>Principio de Seguridad:</strong> la información sujeta a tratamiento deberá manejarse con las medidas técnicas, humanas y administrativas que sean necesarias para otorgar seguridad a los registros evitando su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.</p>
            <p><strong>Principio de Confidencialidad:</strong> <strong>ELECTRIREDES LUSSAN SAS</strong><strong>, </strong>está obligada a garantizar la reserva de la información, inclusive después de finalizada su relación con alguna de las labores que comprende el tratamiento, pudiendo sólo realizar suministro o comunicación de datos personales cuando ello corresponda al desarrollo de las actividades autorizadas en la ley.</p>
            <p>&nbsp;</p>
            <ol>
              <li><strong> Definiciones principales</strong></li>
            </ol>
            <p>&nbsp;</p>
            <p>Las expresiones utilizadas en mayúsculas en esta Política tendrán el significado que aquí se les otorga, o el significado que la ley o la jurisprudencia aplicable les den, y según dicha ley o jurisprudencia sea modificada en el tiempo.</p>
            <ol>
              <li><strong>a) “Autorización”:</strong> Es el consentimiento previo, expreso e informado del Titular para llevar a cabo el Tratamiento de sus Datos Personales.</li>
              <li><strong>b) “Base de Datos”:</strong> Es el conjunto organizado de Datos Personales que sean objeto de Tratamiento, electrónico o no, cualquiera que fuere la modalidad de su formación, almacenamiento, organización y acceso.</li>
              <li><strong>c) “Dato Personal”:</strong> Es cualquier información de cualquier tipo, vinculada o que pueda asociarse a una o varias personas naturales o jurídicas determinadas o determinables.</li>
              <li><strong>d) “Dato Público”:</strong> Es el Dato Personal calificado como tal según los mandatos de la ley o de la Constitución Política y aquel que no sea semiprivado, privado o sensible. Son públicos, entre otros, los datos relativos al estado civil de las personas, a su profesión u oficio, a su calidad de comerciante o de servidor público y aquellos que puedan obtenerse sin reserva alguna. Por su naturaleza, los datos públicos pueden estar contenidos, entre otros, en registros públicos, documentos públicos, gacetas y boletines oficiales, sentencias judiciales debidamente ejecutoriadas que no estén sometidas a reserva.</li>
              <li><strong>e) “Dato Sensible”:</strong> Se entiende por datos sensibles, aquellos que afectan la intimidad del Titular o cuyo uso indebido puede generar su discriminación, tales como aquellos que revelen el origen racial o étnico, la orientación política, las convicciones religiosas o filosóficas, la pertenencia a sindicatos, organizaciones sociales, de derechos humanos o que promueva intereses de cualquier partido político o que garanticen los derechos y garantías de partidos políticos de oposición, así como los datos relativos a la salud, a la vida sexual y los datos biométricos.</li>
              <li><strong>f) “Encargado del Tratamiento”:</strong> Es la persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, realice el Tratamiento de Datos Personales por cuenta del responsable del Tratamiento.</li>
              <li><strong>g) “Titular”</strong> del Dato Personal: Es la persona natural o jurídica a quien se refiere la información que reposa en una Base de Datos, y quien es el sujeto del derecho de hábeas data.</li>
              <li><strong>h) “Autorizado”:</strong> Es la empresa <strong>ELECTRIREDES LUSSAN SAS</strong><strong>,</strong> y todas las personas bajo la responsabilidad de esta que por virtud de la Autorización y de estas Políticas tienen legitimidad para Tratar los Datos Personales del Titular.</li>
              <li><strong>i) “Habilitación”:</strong> Es la legitimación que expresamente y por escrito mediante contrato o documento que haga sus veces, otorgue la Compañía a terceros, en cumplimiento de la Ley aplicable, para el Tratamiento de Datos Personales, convirtiendo a tales terceros en Encargados del Tratamiento de los Datos Personales entregados o puestos a disposición.</li>
              <li><strong>j) “Responsable de Tratamiento”:</strong> Es la persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, decida sobre la Base de Datos y/o el Tratamiento de los Datos Personales.</li>
              <li><strong>k) “Transferencia”:</strong> Es el Tratamiento de Datos Personales que implica la comunicación de estos, dentro o fuera del territorio de la República de Colombia cuando tenga por objeto la realización de un Tratamiento por el Encargado por cuenta del responsable.</li>
              <li><strong>l) “Transmisión”:</strong> Es la actividad de Tratamiento de Datos Personales mediante la cual se comunican los mismos, internamente o con terceras personas, dentro o fuera del territorio de la República de Colombia, cuando dicha comunicación tenga por objeto la realización de cualquier actividad de Tratamiento por el receptor del Dato Personal<strong>.</strong></li>
              <li><strong>m) “Tratamiento de Datos Personales”:</strong> Es toda operación y procedimiento sistemático, electrónico o no, que permita la recolección, conservación, ordenamiento, almacenamiento, modificación, relacionamiento, uso, circulación, evaluación, bloqueo, destrucción y en general, el procesamiento de Datos Personales, así como también su transferencia a terceros a través de comunicaciones, consultas, interconexiones, cesiones, mensajes de datos.</li>
            </ol>
            <p>&nbsp;</p>
            <p><strong>III.&nbsp; Tratamiento y Finalidades</strong></p>
            <p>&nbsp;</p>
            <p>Los Datos Personales tratados por <strong>ELECTRIREDES LUSSAN SAS</strong><strong>, </strong>deberán someterse estrictamente y únicamente a las finalidades que se señalan a continuación. Así mismo, los encargados o terceros que tengan acceso a los Datos Personales por virtud de Ley o contrato mantendrán el Tratamiento dentro de las siguientes finalidades:</p>
            <ol>
              <li>a) Gestionar toda la información necesaria para el cumplimiento de las obligaciones tributarias y de registros comerciales, corporativos y contables de <strong>ELECTRIREDES LUSSAN SAS</strong></li>
              <li>b) Cumplir con los procesos internos de <strong>ELECTRIREDES LUSSAN SAS</strong><strong>, </strong>en materia de administración de proveedores, contratistas y empleados.</li>
              <li>c) Cumplir los contratos de servicios y laborales celebrados con los clientes y trabajadores.</li>
              <li>d) Prestar sus servicios de acuerdo con las necesidades particulares de los clientes de <strong>ELECTRIREDES LUSSAN SAS</strong>, con el fin de cumplir los contratos de servicios celebrados, incluyendo, pero sin limitarse a la verificación de afiliaciones y derechos de los individuos a los cuales los clientes de <strong>ELECTRIREDES LUSSAN SAS</strong><strong>, </strong>prestarán sus servicios.</li>
              <li>e) Las demás finalidades que determinen los responsables en procesos de obtención de Datos Personales para su Tratamiento y que sean comunicadas a los Titulares en el momento de la recolección de los datos personales.</li>
              <li>f) El proceso de archivo, de actualización de los sistemas, de protección y custodia de información y bases de datos de <strong>ELECTRIREDES LUSSAN SAS</strong></li>
              <li>g) Procesos al interior de <strong>ELECTRIREDES LUSSAN SAS</strong><strong>, </strong>con fines de desarrollo operativo y/o de administración de sistemas.</li>
              <li>h) La transmisión de datos a terceros con los cuales se hayan celebrado contratos con este objeto, para fines comerciales, administrativos, de mercadeo y/o operativos, incluyendo, pero sin limitarse a la expedición de carnés, certificados personalizados y certificaciones a terceros, de acuerdo con las disposiciones legales vigentes.</li>
              <li>i) Mantener y procesar por computadora u otros medios, cualquier tipo de información relacionada con el negocio del cliente con el fin de brindar los servicios y productos pertinentes.</li>
              <li>j) Las demás finalidades que determinen los responsables en procesos de obtención de Datos Personales para su Tratamiento, con el fin de dar cumplimiento a las obligaciones legales y regulatorias.</li>
              <li>k) Efectuar las gestiones pertinentes para el desarrollo de la etapa precontractual, contractual y pos contractual de cualquiera de los productos y servicios ofrecidos, así como dar cumplimiento a la ley colombiana y a las órdenes de autoridades judiciales o administrativas.</li>
              <li>l) Gestionar trámites (solicitudes, quejas, reclamos), realizar análisis de riesgo, efectuar encuestas de satisfacción respecto de los bienes y servicios de la empresa.</li>
              <li>m) Suministrar información de contacto y documentos pertinentes a la fuerza comercial y/o red de distribución, telemercadeo, investigación de mercados y cualquier tercero con el cual la empresa posea un vínculo contractual de cualquier índole.</li>
              <li>n) Dar a conocer, transferir y/o trasmitir los datos personales dentro y fuera del país, a terceros a consecuencia de un contrato, ley o vínculo lícito que así lo requiera o para implementar servicios ofrecidos por <strong>ELECTRIREDES LUSSAN SAS</strong></li>
              <li>o) Para el envío y recepción de elementos o material publicitario de acuerdo con los requerimientos de las funciones demandadas y sostenibilidad de los negocios de la empresa.</li>
              <li>p) Controlar y prevenir el fraude en cualquiera de sus modalidades.</li>
              <li>q) Desarrollar el proceso de selección, evaluación y vinculación laboral.</li>
              <li>r) Registrar la información de empleados y/o pensionados (activos o inactivos) en las bases de datos de <strong>ELECTRIREDES LUSSAN SAS</strong></li>
              <li>s) Recaudar cartera y realizar cobro administrativo prejudicial y judicial.</li>
              <li>t) Actualización de datos entregados por los trabajadores, proveedores, prestadores de servicios, personas naturales y jurídicas.</li>
              <li>u) Para el envío de información, a través de correo, mensajes de texto (SMS y/o MMS) o de cualquier otro medio de comunicación sobre el estado, deberes y derechos de los proveedores, prestadores de servicios, personas naturales y jurídicas.</li>
            </ol>
            <p>&nbsp;</p>
            <ol>
              <li><strong> Responsable y Encargado del Tratamiento de Datos.</strong></li>
            </ol>
            <p>&nbsp;</p>
            <p>El responsable del tratamiento de datos personales y otra información de los Titulares es <strong>ELECTRIREDES LUSSAN SAS</strong><strong>, </strong>con domicilio y dirección en la Kra 8 Este No. 25 60 Sur en Bogotá D.C. teléfonos: <strong>2069259 / 3102474432 Correo electrónico: Soporte@electriredes.com</strong></p>
            <p>&nbsp;</p>
            <ol>
              <li><strong> Derechos de los Titulares de la información.</strong></li>
            </ol>
            <p>&nbsp;</p>
            <p>Conforme a la legislación vigente, los titulares de la información tienen el derecho de conocer, actualizar, rectificar su información, y/o revocar la autorización para su tratamiento. En especial, de conformidad con el artículo 8 de la Ley 1581 de 2012, son derechos de los Titulares los siguientes:</p>
            <ol>
              <li>a) Conocer, actualizar y rectificar sus datos personales.</li>
              <li>b) Solicitar prueba de la autorización otorgada.</li>
              <li>c) Ser informado, previa solicitud, respecto del uso que les ha dado a sus datos personales;</li>
              <li>d) Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones a lo dispuesto en la ley.</li>
              <li>e) Revocar la autorización y/o solicitar la supresión del dato.</li>
              <li>f) Acceder en forma gratuita a sus datos personales que hayan sido objeto de Tratamiento.</li>
            </ol>
            <p>&nbsp;</p>
            <ol>
              <li><strong> Procedimientos para ejercer los derechos de los Titulares de los Datos Personales.</strong></li>
            </ol>
            <p>En caso de que desee ejercer sus derechos, el Titular deberá enviar un correo electrónico o físico a las direcciones de contacto establecidas en la presente Política de Tratamiento. El procedimiento que se seguirá para dichas comunicaciones, serán los que se indican a continuación:</p>
            <p>&nbsp;</p>
            <ol>
              <li><strong> Peticiones y Consultas Sobre Datos Personales. </strong></li>
            </ol>
            <p>&nbsp;</p>
            <p>Los Titulares o sus causahabientes podrán consultar la información personal del Titular que repose en <strong>ELECTRIREDES LUSSAN SAS</strong><strong>,</strong> quien suministrará toda la información contenida en el registro individual o que esté vinculada con la identificación del Titular.</p>
            <p>La consulta se formulará a través del correo: <strong>soporte@electrirede.com</strong></p>
            <p>La respuesta a la solicitud se realiza en un plazo máximo diez (10) días hábiles en cumplimiento a lo dispuesto en la Ley 1581 de 2012, cuando no fuere posible atender la consulta dentro de dicho término, se informará al Titular, se le expresarán los motivos de la demora y se le señalará la fecha en que se atenderá su consulta, la cual no podrá superar los cinco (5) días hábiles siguientes al vencimiento del primer término.</p>
            <p><strong>&nbsp;</strong></p>
            <ol>
              <li><strong> Petición de actualización, rectificación o supresión de datos y reclamos sobre datos personales. </strong></li>
            </ol>
            <p>&nbsp;</p>
            <p>Cuando el titular de los datos o sus causahabientes consideren que la información contenida en las bases de datos debe ser objeto de corrección, actualización o supresión, o cuando adviertan el presunto incumplimiento de cualquiera de los deberes contenidos en la Ley 1581 de 2012, podrán presentar un reclamo ante <strong>ELECTRIREDES LUSSAN SAS</strong>, el cual será tramitado bajo las siguientes reglas:</p>
            <ol>
              <li>El reclamo se formulará mediante solicitud dirigida a <strong>ELECTRIREDES LUSSAN SAS</strong>, con la identificación de los Titulares, la descripción de los hechos que dan lugar al reclamo, la dirección, y se anexarán los documentos que se quieran hacer valer. Si el reclamo resulta incompleto, <strong>ELECTRIREDES LUSSAN SAS</strong>, podrá requerir al interesado dentro de los cinco (5) días siguientes a la recepción del reclamo para que subsane las fallas. Transcurridos dos (2) meses desde la fecha del requerimiento, sin que el solicitante presente la información requerida, se entenderá que ha desistido del reclamo. En el evento en que <strong>ELECTRIREDES LUSSAN SAS</strong>, no sea competente para resolver el reclamo, dará traslado a quien corresponda en un término máximo de dos (2) días hábiles e informará de la situación al Titular, con lo cual quedará relevada de cualquier reclamación o responsabilidad por el uso, rectificación o supresión de los datos.</li>
              <li>Una vez recibido el reclamo completo, cuando este no pueda ser resuelto de manera expedita y siempre y cuando sea técnicamente posible, se incluirá en la base de datos una leyenda que diga «reclamo en trámite» y el motivo de este, en un término no mayor a dos (2) días hábiles. Dicha leyenda deberá mantenerse hasta que el reclamo sea decidido.</li>
              <li>El término máximo para atender el reclamo será de quince (15) días hábiles contados a partir del día siguiente a la fecha de su recibo. Cuando no fuere posible atender el reclamo dentro de dicho término, se informará al Titular los motivos de la demora y la fecha en que se atenderá su reclamo, la cual en ningún caso podrá superar los ocho (8) días hábiles siguientes al vencimiento del primer término. El retiro o supresión no procederá cuando exista un deber contractual o legal de permanecer en la base de datos de <strong>ELECTRIREDES LUSSAN SAS</strong>, como, por ejemplo, para el cumplimiento de deberes contables, tributarios, comerciales o jurídicos.</li>
            </ol>
            <p>&nbsp;</p>
            <ol>
              <li><strong> Revocatoria de la autorización y/o supresión del dato.</strong></li>
            </ol>
            <p>&nbsp;</p>
            <p>Los titulares de los datos personales pueden revocar el consentimiento al tratamiento de sus datos personales en cualquier momento, siempre y cuando no lo impida una disposición legal o contractual, para ello <strong>ELECTRIREDES LUSSAN SAS</strong><strong>, </strong>pondrá a disposición del Titular el correo electrónico: <strong>soporte@electriredes.com</strong></p>
            <p>Si vencido el término legal respectivo, <strong>ELECTRIREDES LUSSAN SAS</strong>, según fuera el caso, no hubieran eliminado los datos personales, el Titular tendrá derecho a solicitar a la Superintendencia de Industria y Comercio que ordene la revocatoria de la autorización y/o la supresión de los datos personal. Para estos efectos se aplicará el procedimiento descrito en el artículo 22 de la Ley 1581 de 2012</p>
            <p>&nbsp;</p>
            <ol>
              <li><strong>d</strong>. <strong>Supresión, eliminación y digitalización de datos personales.</strong></li>
            </ol>
            <p>&nbsp;</p>
            <p>En caso de que <strong>ELECTRIREDES LUSSAN SAS</strong>, no desee o requiera la información suministrada por el titular, podrá realizar la destrucción de dicha información o eliminación si se encuentra en medio físico, o la eliminación si se encuentra en medio magnético.</p>
            <p>En todo caso <strong>ELECTRIREDES LUSSAN SAS</strong>, se reserva el derecho de digitalizar la información y tenerla bajo su custodia, siempre y cuando no medie documento que lo prohíba.</p>
            <p>&nbsp;</p>
            <ol>
              <li><strong>e</strong>. <strong>Transferencia, cesión y transmisión De Datos Personales</strong></li>
            </ol>
            <p>&nbsp;</p>
            <p><strong>ELECTRIREDES LUSSAN SAS </strong>podrá revelar a los departamentos internos y sus empleados los Datos Personales sobre los cuales realiza el Tratamiento, para su utilización y Tratamiento conforme a esta Política de Protección de Datos Personales. En estos casos, se exigirá que la información sea tratada conforme a esta Política de Protección de Datos Personales. <strong>ELECTRIREDES LUSSAN SAS </strong>no podrá entregar los Datos Personales a terceros no vinculados a la Empresa.</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p><strong>VII. Vigencia</strong></p>
            <p>&nbsp;</p>
            <p>VIGENCIA Esta política de Protección de Datos Personales está vigente desde el <strong>27 de octubre <em>de 2020</em></strong><em>. <strong>ELECTRIREDES LUSSAN SAS</strong><strong>,</strong> informa que el Trata</em>miento de la Información está sujeto a la Política de Protección de Datos Personales de <strong>ELECTRIREDES LUSSAN SAS</strong>, la cual se encuentra como adjunto y podrá ser consultada en el sitio web www.electriredes.com. <strong>ELECTRIREDES LUSSAN SAS</strong>, hace uso del mecanismo consagrado en el Artículo 10 Decreto 1377 de 2013, por el cual queda autorizada de manera expresa e inequívoca para mantener y manejar toda su información, a no ser que usted le manifieste lo contrario de manera directa, expresa, inequívoca y por escrito dentro de los treinta (30) días hábiles siguientes a la recepción del presente mensaje a la cuenta de correo electrónico disponible para tal efecto soporte@electriredes.com</p>
            <p>Los Datos Personales que sean almacenados, utilizados o transmitidos permanecerán en nuestra Base de Datos, con base en el criterio de temporalidad y necesidad, durante el tiempo que sea necesario para las finalidades mencionadas en esta Política, para las cuales fueron recolectados</p>
          </div>

        </Wrapper>
      </Section>
    </main>
  </Layout>
);

export default DataPage;

export const Head: HeadFC = () => <Seo title="Nosotros" />;
